import { LANGUAGE_TO_LOCALE, Types } from 'common';
import getSearchQuery from '../../utils/queries/getSearchQuery';
import getContentsDefinitionStartDateFilter from '../../utils/queries/getContentsDefinitionStartDateFilter';
import { ContentContentTypeFilter } from '../../components/Filters';
import { formatDateForDB } from 'components';
import Dayjs from 'dayjs';

type SearchQuery = Types.SearchQuery;

export const TOPICS_PER_PAGE = 7;
export const COURSES_PER_PAGE = 5;
export const WEBUPS_PER_PAGE = 4;
export const LECTURES_PER_PAGE = 5;

/**
 * Filter contents without rating and contents with rating greater or equal than 4.3 over 5.
 */
export const ratingThresholdFilter: Types.SearchFilter = {
	field: 'rating',
	value: ['4.3'],
	operation: Types.SearchFilterOperation.GreaterThanEqual,
	valueWithNull: true
};

type GetTopicsSearchQueryProps = {
	pageNumber?: SearchQuery['pageNumber'];
	pageSize?: SearchQuery['pageSize'];
	filters?: SearchQuery['filters'];
	levels?: Array<string>;
	parentCode?: string | null;
	orders?: SearchQuery['orders'];
};

export const getTopicsSearchQuery = ({
	pageNumber,
	pageSize,
	filters,
	levels,
	parentCode,
	orders = []
}: GetTopicsSearchQueryProps): { searchQuery: SearchQuery } => {
	return getSearchQuery({
		pageNumber,
		pageSize,
		filters: [
			...(filters ?? []),
			...(getTopicLevelFilters(levels) ?? []),
			...(getTopicParentFilters(parentCode) ?? []),
			...[
				{
					field: 'activated',
					operation: Types.SearchFilterOperation.Equal,
					value: ['true']
				}
			]
		],
		orders
	});
};

const getTopicLevelFilters = (levels?: Array<string>): SearchQuery['filters'] => {
	if (!levels || levels.length < 1) {
		return null;
	}
	return [{ field: 'level', operation: Types.SearchFilterOperation.Equal, value: levels }];
};

const getTopicParentFilters = (parentCode?: string | null): SearchQuery['filters'] => {
	if (!parentCode) {
		return null;
	}
	return [
		{ field: 'parentCode', operation: Types.SearchFilterOperation.Equal, value: [parentCode] }
	];
};

type GetLecturesSearchQueryProps = {
	pageNumber: number;
	countryCode: string;
	filters?: SearchQuery['filters'];
	language?: Language | null;
	orders?: SearchQuery['orders'];
	includeAdditionalHomePageFilters?: boolean;
};

export const getLecturesSearchQuery = ({
	pageNumber,
	countryCode,
	filters,
	language,
	orders = [],
	includeAdditionalHomePageFilters
}: GetLecturesSearchQueryProps): { variables: { searchQuery: SearchQuery } } => ({
	variables: getSearchQuery({
		pageNumber,
		pageSize: LECTURES_PER_PAGE,
		filters: [
			...(includeAdditionalHomePageFilters
				? [
						{
							field: 'isAvailableOnHomepage',
							operation: Types.SearchFilterOperation.Equal,
							value: ['true']
						},
						{
							field: 'validDate',
							operation: Types.SearchFilterOperation.GreaterThan,
							value: [formatDateForDB(Dayjs.utc().startOf('hour'))]
						}
				  ]
				: []),
			{
				field: 'availableForMembers',
				operation: Types.SearchFilterOperation.Equal,
				value: ['true']
			},
			{
				field: 'publishedDomains',
				operation: Types.SearchFilterOperation.Equal,
				value: [countryCode]
			},
			{
				field: 'contentType',
				operation: Types.SearchFilterOperation.Equal,
				value: [Types.ContentType.Lecture]
			},
			...(language
				? [
						{
							field: 'language',
							operation: Types.SearchFilterOperation.Contain,
							value: LANGUAGE_TO_LOCALE[language]
						}
				  ]
				: []),
			...(filters ?? []),
			...(getContentsDefinitionStartDateFilter(ContentContentTypeFilter.LECTURE) ?? [])
		],
		orders: [...(orders || []), '-startDateTime']
	})
});
